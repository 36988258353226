import {
  HAS_OPACITY_CLASS,
  IS_HOVERED_CLASS,
  IS_SCALED_CLASS,
  IS_HIGHLIGHTED_CLASS,
  VANISHED_CLASS,
} from "./constants";

const heroItem = $(".hero__salon");
const heroBg = $(".hero__salon-bg");

const firstHref = document.querySelector(".hero__salon--first");
const secondHref = document.querySelector(".hero__salon--second");
const bodyAfter = document.querySelector(".page-id-14");
const heroSalonBg = document.querySelector(".hero__salon-bg");

window.onload = function () {
  if (bodyAfter) {
    bodyAfter.classList.add("after-50");
  }
  if (heroSalonBg) {
    heroSalonBg.classList.add("hero__salon-bg--after");
  }
};

if (firstHref) {
  firstHref.addEventListener("mouseenter", () => {
    bodyAfter.classList.remove("after-50");
    bodyAfter.classList.add("after-75");
  });

  firstHref.addEventListener("mouseleave", () => {
    bodyAfter.classList.remove("after-75");
    bodyAfter.classList.add("after-50");
  });

  secondHref.addEventListener("mouseenter", () => {
    const heroSalonBgAfter = document.querySelector(".hero__salon-bg--after");
    if (heroSalonBgAfter) {
      heroSalonBgAfter.classList.remove("hero__salon-bg--after");
      heroSalonBgAfter.classList.add("hero__salon-bg--opacity");
    }
    bodyAfter.classList.remove("after-50");
    bodyAfter.classList.remove("after-75");
    bodyAfter.classList.add("after-25");
  });

  secondHref.addEventListener("mouseleave", () => {
    const heroSalonBgOpacity = document.querySelector(
      ".hero__salon-bg--opacity"
    );
    if (heroSalonBgOpacity) {
      heroSalonBgOpacity.classList.remove("hero__salon-bg--opacity");
      heroSalonBgOpacity.classList.add("hero__salon-bg--after");
    }
    bodyAfter.classList.remove("after-25");
    bodyAfter.classList.remove("after-75");
    bodyAfter.classList.add("after-50");
  });
}

heroItem.on({
  mouseenter: (ev) => {
    heroItem.addClass(VANISHED_CLASS);
    $(ev.target).closest(".hero__salon").addClass(IS_HIGHLIGHTED_CLASS);
    heroBg.css(
      "width",
      $(ev.target).closest(".hero__salon").attr("data-width") + "vw"
    );
    $(".hero__title, .hero__text").addClass(HAS_OPACITY_CLASS);
  },
  mouseleave: (ev) => {
    heroItem.removeClass(VANISHED_CLASS);
    $(ev.target).closest(".hero__salon").removeClass(IS_HIGHLIGHTED_CLASS);
    heroBg.css("width", "50vw");
    $(".hero__title, .hero__text").removeClass(HAS_OPACITY_CLASS);
  },
});
