require("fslightbox");
import Macy from "macy";

const indoorImagesGrid =
	$(".indoor").length &&
	Macy({
		container: ".indoor",
		trueOrder: true,
		margin: {
			x: 4,
			y: 4,
		},
		breakAt: {
			960: {
				columns: 3,
			},
			767: {
				columns: 2,
			},
		},
	});
