import "./libs/jquery.pagenav";
// const setActiveElement = (links, activeClass = "Active") => {
// 	const getPositionFromTop = $(window).scrollTop();

// 	const allSections = (() => {
// 		const sectionsArray = [];
// 		links.each((link) => {
// 			return sectionsArray.push(links.eq(link).attr("href"));
// 		});
// 		return sectionsArray;
// 	})();

// 	const issetSection = (section) => {
// 		if (!$(`#${section}`).length) {
// 			return;
// 		}
// 	};

// 	const getActiveSection = () => {
// 		issetSection();
// 		allSections.forEach((section) => {
// 			if ($(section).offset().top < getPositionFromTop) {
// 				console.log(section);
// 				return section;
// 			}
// 		});
// 	};

// 	const setActiveSection = (section) => {
// 		links.removeClass(activeClass);
// 		$(`a[href=\\#${section}`).addClass(activeClass);
// 	};

// 	allSections.forEach((section) => {
// 		const currentSection = getActiveSection();

// 		setActiveSection(currentSection);

// 		console.log(currentSection);

// 		// if ($("#" + section).length) {
// 		// 	if ($("#" + section).offset().top < getPositionFromTop) {
// 		// 		$(links).removeClass("active");
// 		// 		$(".menu ul li a[href=#" + section + "]").addClass("active");
// 		// 		return;
// 		// 	} else if (
// 		// 		$("#" + allSections[0]).length &&
// 		// 		$("#" + allSections[0]).offset().top > getPositionFromTop
// 		// 	) {
// 		// 		$(".menu ul li a").removeClass("active");
// 		// 	}
// 		// } else {
// 		// 	return;
// 		// }
// 	});
// };

// $(window).on("scroll", function () {
// 	setActiveElement($(".pricing__menu a"));
// });

$(".pricing__menu a").pageNav();
