const section = $("[data-instagram-scroll]");
let isLoading = false;

const handleInstagramLoadImages = () => {
	const isOnTheBottom =
		$(window).scrollTop() + $(window).height() > section.height();
	const nextLink = section.attr("data-instagram-pagination-link");
	const salon = section.attr("data-instagram-salon");

	if (!nextLink) return;

	if (isOnTheBottom && !isLoading) {
		loadPosts(nextLink, salon);
		isLoading = true;
	}
};

const loadPosts = (nextLink, salon) => {
	fetch(
		`${site.ajax_url}?action=instagram_get_posts&next=${nextLink}&salon=${salon}`
	)
		.then((res) => res.json())
		.then((res) => {
			section.append(res.data.html);
			section.attr("data-instagram-pagination-link", res.data.link);
			isLoading = false;
		});
};

$(window).on("scroll", handleInstagramLoadImages);
