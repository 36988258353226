import {
	HEADER_MENU_CLASS,
	MENU_ACTIVE_CLASS,
	HAMBURGER_ACTIVE_CLASS,
} from "./constants";
const header = $(".header");
const hamburgerBtn = $(".hamburger");
const menu = $(".menu");

const toggleMenu = () => {
	menu.toggleClass(MENU_ACTIVE_CLASS);
	header.toggleClass(HEADER_MENU_CLASS);
	hamburgerBtn.toggleClass(HAMBURGER_ACTIVE_CLASS);
};

hamburgerBtn.on("click", toggleMenu);
