import Swiper from "swiper";
import SwiperCore, { Autoplay, Navigation } from "swiper/core";

SwiperCore.use([Autoplay, Navigation]);

const carousel = new Swiper(".carousel", {
  spaceBetween: 10,
  //   slidesPerView: 1,
  slidesPerView: "auto",
  grabCursor: true,
  autoplay: {
    delay: 3000,
  },
  //   breakpoints: {
  //     767: {
  //       slidesPerView: "auto",
  //     },
  //   },
  navigation: {
    nextEl: ".carousel-navigation__right",
    prevEl: ".carousel-navigation__left",
  },
});
