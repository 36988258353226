import "./HeroHover";
import "./carousel";
import "./IndoorImages";
import "./Menu";
import "./ServicesNavigationMenu";
import "./GalleryItem";
import "./InstagramScroll";

const btn = document.querySelector(".pricing__btn");

btn?.addEventListener("click", (ev) => {
	ev.preventDefault();
	document.querySelector(".booksy-widget-button").click();
});
